import React, { useState, useRef, useEffect } from "react";

import style from "./Faqs.module.css";

import Cart from "../../layout/cart/Cart";
import FAQ from "../../component/faq/Faq";
import PrivacyHeading from "../../component/privacyHeading/PrivacyHeading";
import { Helmet } from "react-helmet";



const Faqs = () => {

    const faqData = [
        {
          question: 'What is the recommended age range for My ZOO Box?',
          answer: "We have 2 clubs to meet the needs of a wide range of developmental levels. Cub Club for ages 3-5 focuses on foundational preschool skills in a fun way with animals. Zoologist Club for ages 5-12 focuses on learning about one animal per month and brings STEM, math, life skills, and more to your little zoologist.          ",
        },
        {
          question: 'Can I give My ZOO Box as a gift?',
          answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too!',
        },
        {
            question: 'When will my box ship?',
            answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the last day of the month will receive the next month's box. Orders placed in February are for March's Box, etc.",
          },
          {
            question: 'How much does shipping cost?',
            answer: (
              <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.
              <br/>
              
<br/>
Shipping for The ZOO Store is $7.95 for USA customers and $12.95 for Canadian customers.
<br/>
           
              </p>
            )
          },
          {
            question: 'How much does My ZOO Box cost?',
            answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscriptions.      ',
          },
          {
            question: "I haven't received my shipment, what do I do?",
            answer: "If you haven't received a shipment:   After an EDventure or order is shipped, you will receive a tracking email from support@myzoobox.com. You may need to check that these emails are not sent to your Spam folder.      Please click the tracking link in your shipping confirmation email to check the status of your package and see if there were any carrier issues or delays.    Please note, due to increased mail volume USPS is reporting shipping delays and that some shipments are not being scanned which will lead to inaccurate tracking information. We apologize for the inconvenience this may cause and appreciate your patience as the USPS works to deliver your shipment. Please allow an extra 3-10 business days for packages to arrive to U.S. addresses. Additionally, due to COVID-19 related safety measures, domestic and international packages may be delayed 5-14 days. If your EDventure didn’t arrive in time and you would like to give the recipient a gift note, please click here for some printable options.",
          },
          {
            question: 'Is it hard to cancel my subscription?',
            answer: "We hate to see you go! Did you know you can pause your subscription or receive every other month if you need a short break? To set up every other month, please email support@myzoobox.com.To cancel, log into your My Account page, click the “Subscription” tab, scroll down and click 'Manage', then click “Cancel subscription”.",
          }, {
            question: 'What is Zoologist Club?',
            answer: "Zoologist Club, the ultimate zoology subscription for curious kids aged 5 and up, is here to ignite their passion for wildlife and make life easier for parents on the go.Each month, we deliver everything your child needs for thrilling zoological EDventures. Dive into captivating themes like animal camouflage, wildlife habitats, and more, as you unravel the mysteries of the natural world. Designed with young zoologists in mind, Zoologist Club offers a rich blend of science exploration, artistic expression, imaginative play, and beyond. Our hands-on projects are crafted to foster discovery, exploration, and creativity, bringing the wonders of the animal kingdom right to your doorstep. Join us on a journey of learning and excitement that both kids and parents will cherish!",
          },
          {
            question: 'How is each Zoologist Club EDventure designed?',
            answer: "At Zoologist Club, our commitment to providing your child with the best zoology subscription experience goes beyond just delivering a box of fun – it's about fostering growth and curiosity. Our EDventures are meticulously designed by a team of multi-disciplinary experts, which includes educators, child development professionals, and zoologists. Their expertise helps us ensure that each EDventure is developmentally appropriate and packed with enriching projects that spark your child's curiosity and love of learning.This design process is our commitment to you, to make sure your child receives the most innovative and exciting zoology learning month after month. With Zoologist Club, you can trust that your child's curiosity will flourish with every EDventure that arrives at your doorstep. Join us on this EDventure and watch your child's love for zoology grow! ",
          },
          {
              question: 'What concepts does Zoologist Club cover?',
              answer:"We're all about immersing kids in the fascinating world of zoology. Our Zoologist Club subscription explores the wonders of the animal kingdom through captivating, hands-on projects that are designed to spark creativity and curiosity. Each EDventure is a deep dive into enriching topics, from animal behavior and habitats to adaptation and conservation. Engage in innovative STEM projects, enjoy extention DIY activities, and learn all about the animal of the month in our exclusive books! Let your child's curiosity run wild as they embark on a thrilling zoological EDventure with us!",
            },
            {
              question: 'Do animals repeat?',
              answer:"Zoologist Club has a 5 year animal cycle, after 5 years we start the cycle over.",
            }, {
                question: 'What is Cub Club',
                answer: `Cub Club is the ultimate zoology subscription for preschoolers, designed to nurture your child's natural curiosity and creativity while providing busy parents with a comprehensive preschool learning EDventure. Each month, our carefully curated EDventure arrives at your doorstep, packed with everything your child needs for exciting learning about the world of animals.
                We believe in the power of play-based learning, and Cub Club is tailor-made for kids aged 3 to 4. Our EDventures encompass a wide range of activities, including:
                Animal STEM: Dive into the world of animals with science activities that encourage kids to explore and discover fascinating facts about creatures from around the globe.
                Arts and Crafts: Let your child's artistic talents shine through with engaging craft projects that spark their imagination and creativity.
                Imaginative Play: Unleash your child's inner explorer as they embark on imaginative journeys with animal-themed dramatic play surprises   
                More Surprises: Beyond zoology, Cub Club surprises your child with exciting bonus activities that make learning fun and memorable.
                With Cub Club, you'll foster a love for zoology and encourage your child to connect with the natural world. Our mission is to bring hands-on fun and educational experiences right to your door, so you can watch your child's love for animals and learning flourish with each exciting crate. Join Cub Club today and inspire a lifelong passion for zoology!`,
              },
              {
                question: 'How is each Cub Club EDventure designed?',
                answer: `At Cub Club, our commitment to providing your child with the best zoology subscription experience goes beyond just delivering a box of fun – it's about fostering growth and curiosity. Our EDventures are meticulously designed by a team of multi-disciplinary experts, which includes educators, child development professionals, and zoologists. Their expertise helps us ensure that each EDventure is developmentally appropriate and packed with enriching projects that spark your child's curiosity and love of learning.
                This design process is our commitment to you, to make sure your child receives the most innovative and exciting zoology learning month after month. With Cub Club, you can trust that your child's curiosity will flourish with every EDventure that arrives at your doorstep. Join us on this EDventure and watch your child's love for zoology grow!
                
                `,
              },
              {
                  question: 'What concepts does Cub Club cover',
                  answer: `Cub Club is all about igniting your preschooler's passion for learning through hands-on, play-based activities that focus on foundational skills. We believe in providing a solid educational foundation while having loads of fun. Our EDventures are thoughtfully designed to enrich key preschool learning areas such as:
      
      
      
      
                  Numbers and Early Math: Dive into the exciting world of numbers and basic math concepts through interactive activities that make learning math a joyful adventure.
                  
                  
                  
                  
                  Sensory Art: Encourage your child's sensory exploration and creativity with art projects that engage their senses and enhance fine motor skills.
                  
                  
                  
                  
                  Literacy Skills: Foster early literacy skills, including vocabulary and language development, through engaging activities that encourage reading, storytelling, and more.
                  
                  
                  
                  
                  Pretend Play: Fuel imaginative play, social development, and language skills through themed pretend play experiences that transport your child to new worlds, enhancing their storytelling abilities.
                  
                  
                  
                  
                  Nature and Animals: Connect with the natural world and introduce your child to the wonders of zoology as they learn about animals and their habitats, building vocabulary and early literacy skills through captivating exploration.
                  
                  
                  
                  
                  With Cub Club, your child will develop these essential preschool learning skills, while engaging in activities designed to make learning both educational and entertaining. Join us on this exciting educational journey and watch your preschooler's skills and confidence flourish with each Cub Club EDventure!
                  
                  `,
                },
                {
                  question: 'What animals are covered in Cub Club?',
                  answer: `Every Cub Club Subscription starts with the Pangolin EDventure.
      
      
       
      
      
                  Alligator, Elephant, Bee, Panda, Shark, Lion, Otter, Hippo, Owl, Dolphin, Koala, Polar Bear, Flamingo, Sloth, Butterfly, Sea Turtle, Tiger, Rhino, Giraffe, Orca, Bat, Zebra, Kangaroo, Penguin 
                  
                  
                   
                  
                  
                  These are all the animal EDventure that we will cover in the 2 year cycle of Cub Club. (Animals are not listed in any particular order)`,
                }
        // Add more FAQ items as needed
      ];


  return (

       
              <>
               <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
                 <PrivacyHeading title="FAQs" text="Last updated February 01, 2024"/>
    <Cart>
      <h1>Questions about MYZOOBOX</h1>
      <p>Got a question? Check out our FAQ below. There you will find answers to almost all queries and it's supper easy to use too.

If you need support one of our team will answer all of your questions and help with any problems you may have.

Our support staff are available over email Monday - Friday. They will respond to your ticket within 24 hours (Not including Weekends) support@myzoobox.com
</p>
<FAQ data={faqData}/>
    </Cart>
              </>
      
   
  );
};

export default Faqs;
