import React, { useEffect, useRef } from "react";

import style from "./Gift.module.css"
import Button from "../../component/button/button";
import GiftMYzoo from "../../component/giftMyZoo/GiftMyZoo";
import Curiosity from "../../component/curiosity/Curiosity";
import KidsTestimonials from "../../component/kidTestimonials/KidTestimonials";
import Journey from "../../component/journey/Journey";
import FAQ from "../../component/faq/Faq";
import { Helmet } from "react-helmet";
import gif from "../../assets/gif.gif"
const Gift = () =>{
    const faqData = [
        {
          question: 'What is the recommended age range for My ZOO Box?',
          answer: "My ZOO Box is thoughtfully designed to cater to various developmental levels. We offer specialized clubs for different age groups, starting from ages 3 and up. Each club is tailored to provide age-appropriate content and activities that align with your child's cognitive and motor skills, ensuring an engaging and enriching experience at every stage of their learning journey. Whether your child is a curious preschooler or an inquisitive young mind aged 10 and above, we have a club designed to captivate their imagination and promote their educational growth.",
        },
        {
          question: 'Can I give My ZOO Box as a gift?',
          answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too! Be sure to check the “Are you giving this as a gift?” box during checkout so that you can enter a gift message.',
        },
        {
            question: 'When will my box ship?',
            answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the 25th of the month will receive the next month's box. Orders placed the 26th and after will receive the month after that. Orders placed the 25th of May will receive July as their first box. Orders placed the 26st of May will receive July as their first box.",
          },
          {
            question: 'How much does shipping cost?',
            answer: (
              <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.<br/>
              Monthly subscription EDventures<br/>
              Shipping for The ZOO Store $7.95 for USA customers and $12.95 for Canadian customers.<br/>
              You can get free shipping in our online ZOO Store on orders of $75 or more for the U.S.<br/>
            
              All Monthly Club EDventures ship the first 2 weeks of each month.<br/>
              Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S.<br/>
              Orders placed by the last day of the month will receive the next month's box.<br/>
              Orders placed the 1st and after will receive the month after that.<br/>
              Orders placed the 31st of May will receive July as their first box.<br/>
              Orders placed the 1st of July will receive July as their first box.
              </p>
            )
          },
          {
            question: 'How much does My ZOO Box cost?',
            answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscription - these are paid in-full at checkout; savings are calculated based on the price of a single EDventure (EDventure + shipping). We offer free shipping to addresses in the U.S. Shipping costs for International countries vary. To order, visit www.myzoobox.com and click "Join Club".        ',
          },
          {
            question: 'How does a subscription work?  Is it hard to cancel?',
            answer:"A gift subscription is a fantastic way to treat someone special to a series of exciting experiences without the hassle of monthly renewals. Here's how it works:Non-renewing Prepaid Option: When you purchase a gift subscription, you're essentially buying a set number of months (either 6 or 12) of delightful adventures for the recipient. Unlike regular subscriptions, gift subscriptions do not auto-renew, making them a hassle-free choice.  Select Duration and Purchase: Choose between a 6-month or 12-month subscription package, depending on how long you'd like the recipient to enjoy their animal edventures. Once you've made your selection, proceed to checkout.Recipient's Details: During the checkout process, you'll be prompted to enter the recipient's name and address. This ensures that each package is personalized and delivered directly to them, enhancing the element of surprise and delight.Delivery of Animal Edventures: Once the purchase is complete, the recipient can eagerly await their first animal edventure, shipped the 1st two weeks of every month. With each passing month of the gift subscription, they'll receive a new and exciting animal-themed package right at their doorstep.",
          },
        
        // Add more FAQ items as needed
      ];

      
    return(
          <>
              <Helmet>
        <title>Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
              <div className="container">
        <div className={style.banner}>
           <div>
               {/* <img src="/image/icon/cub.webp" className={style.cubIcon}/> */}
             <h1>
             Give The Gift of
Zoo-rific Fun & Discovery
             </h1>
             <p>
             Gifts for every interest and every kid, delivered.
             </p>
             <div className={style.buttonDis}>
             <Button dynamicClass="fillColor" text="Get Started" link="/choose-gift-club"/>
             {/* <Button dynamicClass="borderColor" text="Gift it"/> */}
             </div>
           </div>
       
        </div>
   
       
    
       </div>
           <div className={style.easyBck}>
           <div className="thirdContainer">
           <div className={`pt50 ${style.giftEasy}`}>
             <div className={style.easyImg}>
             <img src={gif} alt="Example GIF"/>
             </div>
             <div className={style.easyContent}>
                 <h1>Gifting made easy!</h1>
                 <div>
                     <span>1</span>
                     <p><b>Pick a club.</b> We offer age-appropriate EDventures for every kid!</p>
                 </div>
                 <div>
                 <span>2</span>
                     <p><b> Delivered monthly. </b>Your EDventure ships within the first two weeks of the month!</p>
                 </div>
                 <div>
                 <span>3</span>
                     <p><b>Gift any way you want.</b> Ship the 1st month to yourself (to gift in person) or directly to the recipient.</p>
                 </div>
             </div>
         </div>
           </div>
        </div>
        <GiftMYzoo/>
        <Curiosity/>
        <KidsTestimonials/>
        <Journey/>
        <div className="pt50 pb50 subContainer">
              <FAQ data={faqData}/>
           </div>
          </>
    )
}

export default Gift;