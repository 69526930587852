import React, { useRef, useState } from "react";
import style from "./Rific.module.css"
import Button from "../../component/button/button";
import Trusted from "../../component/trusted/Trusted";
import FAQ from "../../component/faq/Faq";
import Testimonials from "../../component/testimonials/Testimonials";
import KidLove from "../../component/kidLove/kidLove";

const Rific = () =>{
    const [showFirst, setShowFirst] = useState(false);
    const [showSecond, setShowSecond] = useState(false);
    const section2Ref = useRef(null);

    const scrollToSection = (sectionRef) => {
       section2Ref.current.scrollIntoView({ behavior: 'smooth' });
     };
    const how = [
        {
            icon:"/image/tc.svg",
            title:"Request Your My ZOO Box",
            description:"Choose between the Cub Club subscription for ages 3-5 or the Zoologist Club subscription for ages 5-11, and contact your school coordinator to place your order."
        },
        {
            icon:"/image/ty.svg",
            title:"Unbox Crazy Fun Activities",
            description:"Your box will ship within the first two weeks of each month to your little zoologist! "
        },
        {
            icon:"/image/gi.svg",
            title:"Bask in being the best parent ever!",
            description:"Experience hands-on animal-themed activities, crafts, and engaging learning materials that bring the wonders of the zoo to life—new EDventures delivered every month with My Zoo Box!"
        },
    ]

    const vendor = [
        {
            text:"Excel Academy Charter School",
            time:"10 Days ago"
        },
        {
            text:"Ohio ACE",
            time:"2 Months ago"
        },
        {
            text:"Step up for Students/My Scholar Shop",
            time:"4 Months ago"
        },
        {
            text:"Pacific Charter Insitute",
            time:"6 Months ago"
        },
        {
            text:"Heartland Charter School",
            time:"6 Months ago"
        },
        {
            text:"Harvest Ridge",
            time:"9 Months ago"
        },
        {
            text:"Sky Mountain/South Sutter/Ocean Grove Charter",
            time:"1 Year ago"
        },  
        {
            text:"Suncoast Prep Academy",
            time:"2 Years ago"
        },  
        {
            text:"Granite Mountain Charter School",
            time:"4 Years ago"
        },  
        {
          text:"Heartwood Charter",
          time:"2 Years ago"
      },  
      {
          text:"Elite Charter School",
          time:"4 Years ago"
      }, 
      {
        text:"Visions in Education",
        time:"4 Years ago"
    },
    ]
    const faqData = [
        {
          question: 'What is the recommended age range for My ZOO Box?',
          answer: "We have 2 clubs to meet the needs of a wide range of developmental levels. Cub Club for ages 3-5 focuses on foundational preschool skills in a fun way with animals. Zoologist Club for ages 5-12 focuses on learning about one animal per month and brings STEM, math, life skills, and more to your little zoologist.          ",
        },
        {
          question: 'Can I give My ZOO Box as a gift?',
          answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too!',
        },
        {
            question: 'When will my box ship?',
            answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the last day of the month will receive the next month's box. Orders placed in February are for March's Box, etc.",
          },
          {
            question: 'How much does shipping cost?',
            answer: (
              <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.
              <br/>
            
  <br/>
  Shipping for The ZOO Store is $7.95 for USA customers and $12.95 for Canadian customers.
  <br/>
           
              </p>
            )
          },
          {
            question: 'How much does My ZOO Box cost?',
            answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscriptions.      ',
          },
        ];
    const toggleFirst = () => {
        setShowFirst(!showFirst);
        setShowSecond(false); // Close the second one if open
      };
    
      const toggleSecond = () => {
        setShowSecond(!showSecond);
        setShowFirst(false); // Close the first one if open
      };


      const kids = [
        {
          image:"/image/lov1.png"
        },
        {
          image:"/image/lov2.png"
        },
        {
          image:"/image/lov3.png"
        },
        {
          image:"/image/lov4.png"
        },
        {
          image:"/image/lov5.png"
        },
        {
          image:"/image/lov6.png"
        },
        {
          image:"/image/lov7.png"
        },
        {
          image:"/image/lov8.png"
        },
        {
          image:"/image/lov9.png"
        },
        {
          image:"/image/lov10.png"
        },
        {
          image:"/image/lov11.png"
        },
        {
          image:"/image/lov12.png"
        },
      ]
    return(
        <>
         <div className="container">
            <div className={style.rificBanner}>
              <div className={style.rificContent}>
                <h2>Add fun to your classes with educational adventures!</h2>
                <p>Discover engaging and educational hands-on activities for preschoolers, kindergarteners, and elementary school students!</p>
                <p  className={style.bannerBtn} onClick={() => scrollToSection(section2Ref)}>Find your charter school <img src="/image/down.png"/></p>
              </div>
            </div>


            <div className={style.rificWorks}>
                <h2>How it Works?!</h2>

                <div className={style.worksDis}>
                    {how.map((item)=>{
                        return(
                            <div>
                                <img src={item.icon} alt="icon"/>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className={style.vendor}  ref={section2Ref}> 
              <h2><span>We are an Approved Vendor with</span> the following schools:</h2>

              <div className={style.vendorBox}>
                    {vendor.map((item) =>{
                        return(
                            <div className={style.vendorCheck}>
                                <div>
                                <img src="/image/check.svg" alt="check"/>
                                <p>{item.text}</p>
                                    </div>
                                {/* <span>{item.time}</span> */}
                            </div>
                        )
                    })}
              </div>
            </div>

            <div className={style.placing}>
                <div className={style.placingContent}>
                <h3>Placing Your Order</h3>
              
                    <p>1. Select your EDventure from the options</p>
                    <p>2. Share your desired plan with your school coordinator who can forward purchase order to <a href="mailto:education@myzoobox.com">support@myzoobox.com</a></p>
                    <p>3. Your first box will be delivered within the first two weeks of the month after we have received your purchase order, and again every month for the length of your plan.</p>
               
                <span>Charter School orders do not automatically renew- please request a new order with your school coordinator to continue your subscription!</span>
                </div>
                <div className={style.placingPlans}>
                    <div className={`${style.placingBox}`} onClick={toggleSecond}>
                        <div className={style.placingHeading}>
                            <div>
                                <img src="/image/icon/cubb.svg" alt="icon"/>
                                <p>Cub Club</p>
                            </div>
                            <p>Ages 3-5</p>
                        </div>
                        <div  className={style.mainImage}>
                        <img src="/image/learn.webp" alt="mainImg"/>

                        </div>
                 <p  className={style.click}>{!showSecond ? "Click to see what’s included inside" : "Click to hide this"} <img style={{ rotate: showSecond ? '90deg' : '0deg' }}src="image/icon/bl.svg"/></p>
                        <div className={`${style.placingBottom} ${showSecond ? style.show : ''}`}    >
                            

                               <p>Included Inside:</p>
                               <div>
                          <ul>
                              <li>Stuffed Animal</li>
                              <li>Anatomy Poster & Coloring Sheet</li>
                              <li>STEM Project</li>
                              <li>Postcard from the Animal</li>
                              <li>Themed or Authentic Recipe</li>
                              <li>QR Printables</li>
                          </ul>
                          <ul>
                              <li>Science Book</li>
                              <li>Themed Art Project with Supplies</li>
                              <li>Collectible Sticker</li>
                              <li>The Signature ZooPassport</li>
                              <li>Special SurpriseItem</li>
                            
                          </ul>
                               </div>
              
                        </div>
                    </div>

                    <div className={`${style.placingBox} ${style.placingZoo}`} onClick={toggleFirst}>
                        <div className={style.placingHeading}>
                            <div>
                                <img src="/image/icon/zooo.svg" alt="icon"/>
                                <p>Zoologist Club</p>
                            </div>
                            <p>Ages 5-12</p>
                        </div>
                        <div  className={style.mainImage}>
                        <img src="/image/boxSk.png" alt="mainImg"/>

                        </div>
                        <p  className={style.click}>{!showFirst ? "Click to see what’s included inside" : "Click to hide this"} <img style={{ rotate: showFirst ? '90deg' : '0deg' }}src="image/icon/bl.svg"/></p>
                        <div className={`${style.placingBottom} ${showFirst ? style.show : ''}`}>
                         
                           <p>Included Inside:</p>
                           <div>
                            <ul>
                                <li>Stuffed Animal</li>
                                <li>Anatomy Poster & Coloring Sheet</li>
                                <li>STEM Project</li>
                                <li>Postcard from the Animal</li>
                                <li>Themed or Authentic Recipe</li>
                                <li>QR Printables</li>
                            </ul>
                            <ul>
                                <li>Science Book</li>
                                <li>Themed Art Project with Supplies</li>
                                <li>Collectible Sticker</li>
                                <li>The Signature ZooPassport</li>
                                <li>Special SurpriseItem</li>
                              
                            </ul>
                           </div>
                        </div>
                    </div>
                </div>


                
            </div>
            </div>
            <Trusted/>
            <KidLove kid={kids} title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/>

<Testimonials/>
            <div className="pt50 pb50 subContainer">
              <FAQ data={faqData} />
            </div>
   
        </>
    )
}

export default Rific